export const useAddContainerStore = defineStore('addContainerStore', {
  state: (): any => ({
    disableConfirmButton: false,
    showContainerUploadDrawer: false,
    closeUploadDialog: false,
    manualUploadsContainers: '[{}, {}, {}, {}, {}, {}, {}]',
  }),
  actions: {
    setConfirmButtonToDisable(status: boolean) {
      this.disableConfirmButton = status;
    },
    setContainerUploadDrawerStatus(value: boolean) {
      this.showContainerUploadDrawer = value;
    },
    async uploadBulkContainers(payload: any, streamingData: any = false) {
      const runtimeConfig = useRuntimeConfig();
      const userStore = useUserStore();
      const orgId = userStore.activeOrgId;
      const url = `${runtimeConfig.public.API_URL2}orgs/${orgId}/eta/bookmarks-bulk-upload/`;
      const streaming = streamingData ? '?is_streaming=true' : '';
      const { $ofetch }: any = useNuxtApp();
      return $ofetch(url + streaming, {
        method: 'post',
        body: payload,
      });
    },
    setCloseUploadDialog() {
      this.closeUploadDialog = !this.closeUploadDialog;
    },
    setManualUploadsContainers(payload: any) {
      const data = JSON.parse(this.manualUploadsContainers);
      if (payload?.column?.isCustomField) {
        if (!data[payload.index].custom_fields) {
          data[payload.index].custom_fields = {};
        }
        data[payload.index].custom_fields[payload.column.key] = payload.value;
      }
      else {
        data[payload.index][payload.column] = payload.value;
      }
      this.manualUploadsContainers = JSON.stringify(data);
    },
  },
});
